import { useAppSelector } from '../../../../store/hooks';
import { isOrderStreamingSelector, orderSelector } from '../../../../store/orders/orders.selectors';
import StreamFeature from './StreamFeature';
import { useEffect, useState } from 'react';
import {
  useGetOneManufacturingOrderQuery,
  useGetProductionZipFileMutation
} from '../../../../services/manufacturing-orders-api.services.tsx';
import { WorkflowPostModelingStepEnum } from '../../../../enum/workflow-step.ts';
import FileSaver from 'file-saver';
import { history } from '../../../../models/history.tsx';
import { useNavigate } from 'react-router-dom';

const DesignStep = () => {
  const order = useAppSelector(orderSelector);
  const isStreaming = useAppSelector(isOrderStreamingSelector);
  history.navigate = useNavigate();

  const [skipManufacturingOrderQuery, setSkipManufacturingOrderQuery] = useState<boolean>(true);
  const { data: manufacturingOrder } = useGetOneManufacturingOrderQuery(order?.orderNumber, {
    pollingInterval: 2000,
    skip: skipManufacturingOrderQuery
  });

  const [getProductionZipFile, { data: productionZipFile }] = useGetProductionZipFileMutation();
  useEffect(() => {
    if (manufacturingOrder?.currentStep === WorkflowPostModelingStepEnum.DELIVERED) {
      setSkipManufacturingOrderQuery(true);
      getProductionZipFile(manufacturingOrder.orderNumber);
    }
  }, [manufacturingOrder]);

  useEffect(() => {
    if (productionZipFile) {
      FileSaver.saveAs(productionZipFile, `${order?.orderNumber}.zip`);
      if (history.navigate) history.navigate('/');
    }
  }, [productionZipFile]);

  useEffect(() => {
    if (isStreaming && order) {
      setSkipManufacturingOrderQuery(false);
    }
  }, [isStreaming]);

  return <StreamFeature orderNumber={order?.orderNumber} />;
};

export default DesignStep;
